import React, { useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import { useCurrentUser } from '../../hooks/use-current-user.hook';
import { Onboarding } from './onboarding';
import { Reviewers } from './reviewers';
import type { CreateUserPayload } from './types';

type UserResponse = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  external_user_id: string;
};

export const Dashboard = () => {
  const {
    loading: currentUserLoading,
    data: currentUserData,
    error: currentUserError,
    refetch: refetchCurrentUser,
  } = useCurrentUser();

  const [postCreateUser, { loading: createUserLoading }] = useApiFetch<
    UserResponse | undefined
  >();

  const onSubmit = useCallback(
    async (payload: CreateUserPayload) => {
      await postCreateUser('/api/users/create', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      refetchCurrentUser();
    },
    [postCreateUser, refetchCurrentUser]
  );

  return (
    <Container>
      <Row>
        <h2>Dashboard</h2>
      </Row>
      <Row className="mt-3">
        {currentUserLoading ? (
          <p>Loading...</p>
        ) : currentUserError?.status && currentUserError?.status !== 404 ? (
          <p>{`Fetch error occurred: ${currentUserError}`}</p>
        ) : (
          <>
            {currentUserData ? (
              <>
                <p>
                  Hello {currentUserData.first_name} {currentUserData.last_name}
                </p>
                <Reviewers />
              </>
            ) : (
              <Onboarding submitting={createUserLoading} onSubmit={onSubmit} />
            )}
          </>
        )}
      </Row>
    </Container>
  );
};
