import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';

const MULTI_COUNTRIES: Record<string, string> = {
  US: 'United States',
  WO: 'WIPO',
  EP: 'EPO',
  JP: 'Japan',
  KR: 'South Korea',
  CN: 'China',
  AU: 'Australia',
};

export type SearchQueryFormFields = {
  name?: string | undefined;
  title?: string | undefined;
  inventor?: string | undefined;
  description?: string | undefined;
  abstract?: string | undefined;
  claims?: string | undefined;
  cpcClassification?: string | undefined;
  assignee?: string | undefined;
  countries?: string | undefined; // comma-separated list
};

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: SearchQueryFormFields) => {
  const errors: Partial<SearchQueryFormFields> = {};
  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
};

type SearchQueryFormProps = {
  flow: 'add' | 'edit';
  fetchResultsLoading: boolean | undefined;
  hasTooMayResults: boolean | undefined;
  forceDisableSubmit: boolean | undefined;
  submitError: string | undefined;
  initialValues: SearchQueryFormFields;
  onTest: (values: SearchQueryFormFields) => void;
  onSubmit: (values: SearchQueryFormFields) => void;
};

export const SearchQueryForm = ({
  flow,
  fetchResultsLoading,
  hasTooMayResults,
  forceDisableSubmit,
  submitError,
  initialValues,
  onTest,
  onSubmit,
}: SearchQueryFormProps) => {
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleOnTest = useCallback(
    () => onTest(formik.values),
    [onTest, formik.values]
  );

  const handleOnCountrySelect = useCallback(
    (e: any) => {
      const existingCountries = formik.values.countries?.split(',') || [];
      const selectedCountry = e.target.value;
      const newCountries = existingCountries.includes(selectedCountry)
        ? existingCountries.filter((country) => country !== selectedCountry)
        : [...existingCountries, selectedCountry];

      formik.setFieldValue(
        'countries',
        newCountries.filter((country) => !!country).join(',')
      );
    },
    [formik]
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-5 mt-3" controlId="formTitle">
        <Form.Label>Search Query name</Form.Label>
        <Form.Control
          name="name"
          placeholder="Search Query name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-danger">{formik.errors.name}</div>
        ) : null}
      </Form.Group>
      <hr />
      <Form.Group className="mb-3" controlId="formTitle">
        <Form.Label>Title</Form.Label>
        <Form.Control
          name="title"
          placeholder="Title text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
        />
      </Form.Group>
      {/* only show Description for those who already have a value there */}
      {formik.values.description && (
        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            name="description"
            placeholder="Description text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-3" controlId="formAbstract">
        <Form.Label>Abstract</Form.Label>
        <Form.Control
          name="abstract"
          placeholder="Abstract text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.abstract}
        />
      </Form.Group>
      {/* only show Claims for those who already have a value there */}
      {formik.values.claims && (
        <Form.Group className="mb-3" controlId="formClaims">
          <Form.Label>Claims</Form.Label>
          <Form.Control
            name="claims"
            placeholder="Claims name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.claims}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-3" controlId="formCpcClassification">
        <Form.Label>CPC Classification</Form.Label>
        <Form.Control
          name="cpcClassification"
          placeholder="CPC Classification"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cpcClassification}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formInventor">
        <Form.Label>Inventor</Form.Label>
        <Form.Control
          name="inventor"
          placeholder="Inventor name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.inventor}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formAssignee">
        <Form.Label>Applicant</Form.Label>
        <Form.Control
          placeholder="Applicant"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.assignee}
          name="assignee"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formCountries">
        <Form.Label>Countries</Form.Label>

        <div key={`inline-${'checkbox'}`} className="mb-3">
          {Object.keys(MULTI_COUNTRIES).map((countryCode: string) => (
            <Form.Check
              id={`inline-checkbox-${countryCode}`}
              inline
              label={MULTI_COUNTRIES[countryCode]}
              value={countryCode}
              name="countries"
              type={'checkbox'}
              checked={formik.values.countries?.includes(countryCode)}
              onChange={handleOnCountrySelect}
            />
          ))}
        </div>
      </Form.Group>
      <Button
        variant="secondary"
        className="mr-2"
        onClick={handleOnTest}
        disabled={fetchResultsLoading}
      >
        Find Publications
      </Button>{' '}
      <Button
        variant="primary"
        disabled={
          !formik.isValid ||
          formik.isSubmitting ||
          hasTooMayResults ||
          fetchResultsLoading ||
          forceDisableSubmit
        }
        className="ml-2"
        type="submit"
      >
        {flow === 'add' ? 'Save as new Search Query' : 'Save Search Query'}
      </Button>
      {hasTooMayResults ? (
        <p>{'Please narrow your search to less than 1000 results'}</p>
      ) : submitError ? (
        <p className="text-danger">{submitError}</p>
      ) : null}
    </Form>
  );
};
