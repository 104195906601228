import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import {
  PatentsTable,
  FormattedPatentDoc,
} from '../../components/patents-table';
import {
  SearchQueryForm,
  type SearchQueryFormFields,
} from '../../components/search-query-form';

type SearchFilterResponseType = {
  id: number;
  search_query_id: number;
  field_name: string;
  field_value: string;
};

type SearchQueryResponseType = {
  id: number;
  reviewer_id: number;
  name: string;
  status: string;
  search_filters: SearchFilterResponseType[];
};

export const EditSearchQueryPage = () => {
  const { reviewerId, searchQueryId } = useParams();
  const navigate = useNavigate();

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<any>();

  const [fetchSearchQuery] = useApiFetch<SearchQueryResponseType | undefined>();

  const [putCreateSearchQuery, { error: putCreateSearchQueryError }] =
    useApiFetch<SearchQueryResponseType | undefined>({
      onSuccess: () => navigate(`/reviewer/${reviewerId}`),
    });

  const onSubmit = useCallback(
    async (values: SearchQueryFormFields) => {
      const fields = [
        { name: 'title', value: values.title },
        { name: 'inventor', value: values.inventor },
        { name: 'description', value: values.description }, // DEPRECATED
        { name: 'abstract', value: values.abstract },
        { name: 'claims', value: values.claims },
        {
          name: 'cpcClassification',
          value: values.cpcClassification,
        },
        { name: 'assignee', value: values.assignee },
        { name: 'countries', value: values.countries },
      ].filter((field) => !!field.value);

      putCreateSearchQuery(
        `/api/reviewers/${reviewerId}/search-queries/${searchQueryId}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            reviewerId,
            name: values.name,
            fields,
          }),
        }
      );
    },
    [reviewerId, searchQueryId, putCreateSearchQuery]
  );

  useEffect(() => {
    const _fetchSearchQuery = async () => {
      const response = await fetchSearchQuery(
        `/api/reviewers/${reviewerId}/search-queries/${searchQueryId}`
      );
      if (response) {
        const valuesObj = response.search_filters.reduce(
          (accumulator: any, currentValue) => {
            switch (currentValue.field_name) {
              case 'title':
              case 'inventor':
              case 'description': // DEPRECATED
              case 'abstract':
              case 'claims':
              case 'cpcClassification':
              case 'assignee':
              case 'countries':
                accumulator[currentValue.field_name] = currentValue.field_value;
                break;
              default:
                break;
            }

            return accumulator;
          },
          {}
        );

        setInitialValues({ ...valuesObj, name: response.name });
      }
      setInitialLoad(false);
    };

    if (reviewerId && searchQueryId) {
      _fetchSearchQuery();
    }
  }, [reviewerId, searchQueryId, fetchSearchQuery]);

  const [apiFetch, { loading: fetchResultsLoading, data, error }] = useApiFetch<
    { results: Array<FormattedPatentDoc>; totalCount: number } | undefined
  >();
  const { results, totalCount } = data || {};

  const onTest = useCallback(
    (values: SearchQueryFormFields) => {
      setInitialLoad(false);
      apiFetch('/api/patents/query', {
        method: 'POST',
        body: JSON.stringify({
          title: values.title,
          inventor: values.inventor,
          description: values.description,
          abstract: values.abstract,
          claims: values.claims,
          cpcClassification: values.cpcClassification,
          assignee: values.assignee,
          countries: values.countries,
        }),
      });
    },
    [apiFetch]
  );

  const hasTooMayResults = (totalCount ?? 0) >= 1000;

  return (
    <Container>
      <Row>
        <h2>{`Edit Search Query: ${initialValues?.name ?? ''}`}</h2>
      </Row>

      <Row>
        {initialValues && (
          <SearchQueryForm
            flow="edit"
            fetchResultsLoading={fetchResultsLoading}
            hasTooMayResults={hasTooMayResults}
            forceDisableSubmit={false}
            submitError={putCreateSearchQueryError?.message}
            onTest={onTest}
            onSubmit={onSubmit}
            initialValues={initialValues}
          />
        )}
      </Row>

      <Row className="mt-4">
        <h3>Results:</h3>

        <PatentsTable
          initialLoad={initialLoad}
          loading={fetchResultsLoading}
          error={error}
          results={results}
          totalCount={totalCount}
        />
      </Row>
    </Container>
  );
};
