import { useCallback, useEffect } from 'react';
import { useApiFetch } from '../utils/use-api-fetch.hook';

type UserResponse = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  external_user_id: string;
};

export const useCurrentUser = () => {
  const [fetchCurrentUser, { loading, data, error }] = useApiFetch<
    UserResponse | undefined
  >();
  const getCurrentUser = useCallback(async () => {
    await fetchCurrentUser('/api/users/current');
  }, [fetchCurrentUser]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return {
    loading,
    data,
    error,
    refetch: getCurrentUser,
  };
};
